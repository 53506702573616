import React, { Fragment } from 'react';
import Head from 'next/head';
import Dashboard from '../components/Layouts/Dashboard';

export default function Home() {
  return (
    <Fragment>
      <Head>
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Text-based video editing." />
        <link rel="apple-touch-icon" href="https://edit.simonsaysai.com/simonsays-logo.png" />
        <link rel="manifest" href="/manifest.json" />
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simon Says Assemble" />
        <meta property="og:description" content="Text-based video editing." />
        <meta property="og:site_name" content="Simon Says Assemble" />
        <meta property="og:image" content="https://edit.simonsaysai.com/simonsays-logo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Simon Says Assemble" />
        <meta name="twitter:description" content="Text-based video editing." />
        <meta name="twitter:image" content="https://edit.simonsaysai.com/simonsays-logo.png" />
        <meta name="viewport" content="initial-scale=0" />
        <title>Simon Says | Dashboard</title>
      </Head>
      <Dashboard />
    </Fragment>
  );
}
